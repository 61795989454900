<script>
import PrintA4 from "@/components/print/defaults/PrintA4"

export default {
  components: {PrintA4},
  inject: {
    printView: {
      default () {
        console.error('Main needs to be child of PrintView')
      }
    }
  },
  nome: 'RelatoriosDefaultPrint',
  props: {
    data: {required: true},
    excel: {required: false},
    orientation: {
      type: String,
      default: 'portrait'
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  mounted() {
    if (this.excel) {
      this.printView.printOptions.excel = this.excel
    }
  },
  activated() {
  },
  created() {
  },
  deactivated() {
  },
  watch: {},
  methods: {}
}
</script>

<template>
  <print-a4 :orientation="orientation" :is-loading="isLoading">
    <div v-if="data && data.template" v-html="data.template.template || data.template"></div>
  </print-a4>
</template>
